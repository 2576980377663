<template>
  <v-form
    @submit.prevent
    :rules="validation"
    ref="ruleFormRef"
    class="md:!tw-pt-[25px]"
  >
    <div class="form_input">
      <label for="newPassword">Email Address</label>
      <v-text-field
        bg-color="rgba(12, 15, 74, 0.0509803922)"
        class="input_field c-input"
        :rules="validation.email"
        rounded
        outlined="false"
        v-model="loginForm.email"
        placeholder="Email Address"
        variant="solo"
      ></v-text-field>
    </div>

    <div class="form_input">
      <label for="reEnterPass">Password</label>
      <v-text-field
        bg-color="rgba(12, 15, 74, 0.0509803922)"
        variant="solo"
        class="input_field password__input"
        :class="{ demo: validation.password }"
        :rules="validation.password"
        v-model="loginForm.password"
        :append-inner-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append-inner="() => (isShowPassword = !isShowPassword)"
        :type="isShowPassword ? 'text' : 'password'"
        rounded
        outlined="false"
        placeholder="Enter password"
      ></v-text-field>
    </div>
    <span class="error_msg" v-if="loginErrorMessage">{{
      loginErrorMessage
    }}</span>
    <v-checkbox
      label="Remember Me"
      class="form_input"
      v-model="rememberUser"
    ></v-checkbox>
    <v-btn
      class="button disabled"
      :class="{ 'button-orange': isDisabledNextButton }"
      type="submit"
      block
      size="large"
      :disabled="!isDisabledNextButton"
      :loading="loading"
      @click.enter="onNextButtonClick"
      >Next
    </v-btn>
  </v-form>
  <div class="card-info">
    <div class="card-part">
      <p class="common-title">Forgot Password?</p>
      <label @click="onClickResetPassword" class="yellow-text">
        Reset Your Password<v-icon icon="mdi-chevron-right"
      /></label>
    </div>
    <div class="card-part">
      <p class="common-title">Dont’ have an account?</p>
      <label @click="onClickRegisterButton" class="yellow-text">
        Register<v-icon icon="mdi-chevron-right"
      /></label>
    </div>
  </div>
  <div class="benefit_wrapper llg:!tw-mb-[20px]">
    <div class="title">
      <h6><v-icon icon="mdi-star" />iknowa Benefits</h6>
    </div>
    <h5>Take a look at all the amazing benefits iknowa offers</h5>
    <label @click="openOverview" class="yellow-text"
      >Open iknowa Overview <v-icon icon="mdi-chevron-right"
    /></label>
  </div>
  <AuthIknowaInfoMobileModal
    v-if="isShowAuthIknowaInfoMobile"
    @onCloseButtonClick="onCloseButtonClick"
  />
</template>

<script lang="ts">
import { computed, onMounted, ref, getCurrentInstance } from "vue";

import CryptoJS from "crypto-js";

import { useStore } from "vuex";
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";

import {
  AUTH_SESSION_EXPIRY,
  AUTH_USER,
  CRYPTO_SECRET_KEY,
} from "@/core/constants";
import {
  REGISTRATION_ROUTE,
  VERIFY_REGISTRATION_ROUTE,
} from "../../registration/routes";
import { USER_STORE } from "@/store/modules/user";
import { useRouter } from "vue-router";
import { FORGOT_PASSWORD_ROUTE } from "@/modules/forgot-password/routes";
import { setLocalStorageWithExpiry } from "@/core/utils/common";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import { ONBOARDING_ROUTE } from "@/modules/onboarding/routes";
import { useDisplay } from "vuetify/lib/framework.mjs";
// import AuthIknowaInfoMobile from "@/core/components/AuthIknowaInfoMobile.vue";
import AuthIknowaInfoMobileModal from "@/core/components/AuthIknowaInfoMobileModal.vue";
import { WORKSTATION } from "@/store/modules/workstation";

export default {
  components: {
    // AuthIknowaInfoMobile,
    AuthIknowaInfoMobileModal,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const vuetify = useDisplay();
    const router = useRouter();
    const rememberUser = ref(false);
    const loading = ref(false);
    const loginForm = ref({
      email: "",
      password: "",
    });
    const isShowPassword = ref(false);
    const ruleFormRef = ref(null) as any;
    const loginErrorMessage = ref("");
    const internalInstance = getCurrentInstance();

    const validation = ref({
      email: [
        (v: string) => !!v || "Email is required",
        (v: string) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid email. Try again.",
      ],
      password: [
        (v: string) => !!v || "Password is required",
        (v: string) =>
          // eslint-disable-next-line
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/.test(
            v
          ) ||
          "Password must contain at least 8 characters including at least 1 letter and 1 number",
      ],
    });

    const setRememberUserInCookie = (email: string, password: string) => {
      if (!rememberUser.value) return;
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 30);
      const cookieValue = `${encodeURIComponent(email)}|${encodeURIComponent(
        password
      )}`;
      document.cookie = `rememberMe=${cookieValue}; expires=${expirationDate.toUTCString()}; path=/`;
    };

    onMounted(() => {
      const cookies = document.cookie.split(";");
      const userData = { email: "", password: "" };

      cookies.forEach((cookie) => {
        const [name, value] = cookie.trim().split("=");
        if (name === "rememberMe") {
          userData.email = decodeURIComponent(value).split("|")[0];
          userData.password = decodeURIComponent(value).split("|")[1];
        }
      });
      console.log(
        "====================>>>>>>>>>>>>>>",
        userData.email && userData.password
      );
      if (userData.email && userData.password)
        loginUser(userData.email, userData.password);
    });

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };
    const loginUser = async (email: string, password: string) => {
      try {
        loading.value = true;
        await store.dispatch(`${USER_STORE}/saveUserState`, null);
        const loginedUser = await store.dispatch(
          `${AUTHENTICATION_STORE}/login`,
          {
            username: email,
            password: password,
          }
        );

        const user = await store.dispatch(
          `${USER_STORE}/getUser`,
          loginedUser?.user?.id
        );
        await store.dispatch(
          `${USER_STORE}/setUserProfileImage`,
          user?.userPublicProfile?.profileImage
        );
        await store.dispatch(`${USER_STORE}/saveUserState`, user);

        setLocalStorageWithExpiry(
          {
            roleId: loginedUser?.user?.role?.id,
            userId: loginedUser?.user?.id,
            hasChosenRole: loginedUser?.user?.hasChosenRole,
            hasAccountSetup: loginedUser?.user?.hasAccountSetup,
            hasBusinessType: loginedUser?.user?.hasBusinessType,
          },
          AUTH_USER,
          AUTH_SESSION_EXPIRY
        );

        if (!loginedUser?.user?.hasAccountSetup) {
          router.push({ name: ONBOARDING_ROUTE });
        } else {
          await store.dispatch(
            `${USER_STORE}/saveUserRole`,
            loginedUser?.user?.role.name
          );
          await router.push({ name: DASHBOARD_ROUTE });
        }
      } catch (error: any) {
        console.log(error);
        if (error?.response?.data?.error === "unverified") {
          await store.dispatch(`${AUTHENTICATION_STORE}/resendEmailOTP`, email);
          displayToast(error?.response?.data?.message, "info");

          setTimeout(() => {
            router.push({
              name: VERIFY_REGISTRATION_ROUTE,
              query: { email },
            });
          }, 1500);
        } else {
          loginErrorMessage.value = `Invalid email or password. Try again.`;
          setTimeout(() => {
            loginErrorMessage.value = "";
          }, 5000);
        }
      } finally {
        loading.value = false;
      }
    };

    const onNextButtonClick = async (e: any) => {
      e.preventDefault();

      const isFormValid = await ruleFormRef.value.validate();

      if (isFormValid.valid) {
        const passwordEncrypt = CryptoJS.AES.encrypt(
          loginForm.value.password ?? "",
          CRYPTO_SECRET_KEY ?? ""
        ).toString();
        loginUser(loginForm.value.email, passwordEncrypt);
        setRememberUserInCookie(loginForm.value.email, passwordEncrypt);
      }
    };

    const onClickRegisterButton = () => {
      router.push({ name: REGISTRATION_ROUTE });
    };

    const onClickResetPassword = () => {
      router.push({ name: FORGOT_PASSWORD_ROUTE });
    };

    const isDisabledNextButton = computed(() => {
      return (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          loginForm.value.email
        ) &&
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-]).{8,}$/.test(
          loginForm.value.password
        )
      );
    });

    const isMoblieView = computed(() => vuetify.mdAndDown.value);
    const isShowAuthIknowaInfoMobile = ref(false);
    const onCloseButtonClick = () => {
      isShowAuthIknowaInfoMobile.value = false;
      document.body.classList.remove("noScrollModal");
    };

    const openOverview = () => {
      isShowAuthIknowaInfoMobile.value = true;
      document.body.classList.add("noScrollModal");
    };

    return {
      loginForm,
      rememberUser,
      onNextButtonClick,
      loginErrorMessage,
      onClickRegisterButton,
      onClickResetPassword,
      ruleFormRef,
      isShowPassword,
      validation,
      isDisabledNextButton,
      isMoblieView,
      isShowAuthIknowaInfoMobile,
      onCloseButtonClick,
      openOverview,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/login/styles/login.scss";
</style>
