<template>
  <div class="top_content md:!tw-mt-0">
    <v-btn
      variant="text"
      aria-label="Refresh"
      class="button button-transparent"
      prepend-icon="mdi-chevron-left"
      @click="onClickBackButton"
      >back</v-btn
    >
  </div>
  <div class="step__third md:!tw-gap-4 md:!tw-pb-4 md:!tw-h-[calc(100%-3rem)]">
    <div
      v-if="!isShowProjectReview"
      class="timeline__wrapper push-changes testing-start md:!tw-h-[unset]"
    >
      <div class="timeline__left">
        <div>
          <!-- Standard -->
          <div
            class="timeline__schedule"
            v-if="
              userProjectType === ProjectTypesEnum.STANDARD &&
              currentSelectedTab.id === 3
            "
          >
            <div class="property_badge">
              <div class="notification">
                {{ currentSelectedTab.id }}
              </div>
              <div class="property_badge_text md:!tw-text-[14px]">
                {{ currentSelectedTab.name }}
              </div>
            </div>

            <div class="main_titlesection md:!tw-mt-[2px]">
              <h3 class="md:!tw-text-[18px]">{{ currentSelectedTab.title }}</h3>
            </div>

            <div class="timeline">
              <div class="timeline__option third_step">
                <div class="timeline__list_cards">
                  <v-card
                    class="timeline__card"
                    v-for="(item, index) in standardTypeList"
                    :key="index"
                    :class="{ selected: item.selected }"
                    @click="toggleSelectionType(item.name)"
                  >
                    <div class="timeline__card__logo">
                      <v-icon v-if="item.iconName" class="list_content_btn">
                        <img
                          v-if="item.iconName"
                          :src="
                            require('@/assets/icons/' + item.iconName + '.svg')
                          "
                          alt="icon"
                        />
                      </v-icon>
                    </div>
                    <div class="timeline__card__text">
                      <h5>{{ item.name }}</h5>
                      <p class="description">
                        {{ item.subTitle }}
                      </p>
                    </div>
                  </v-card>
                </div>

                <v-btn
                  aria-label="Refresh"
                  class="button button-purple desktop__wrapper"
                  @click="onClickToggleContinue"
                  :disabled="!isProjectTypeSelected"
                  >continue</v-btn
                >
              </div>

              <div class="calendar_container" v-if="!isFlexibleTypeSelected">
                <div class="calendar_current_day">
                  <!-- <v-icon icon="mdi-chevron-left" /> -->
                  <p>Today</p>
                  <!-- <v-icon icon="mdi-chevron-right" /> -->
                </div>
                <DatePicker
                  :masks="masks"
                  class="calendar__select"
                  v-model.range="dataRange"
                  mode="date"
                  :disabled-dates="isDatePickerDisable"
                />
                <v-btn
                  aria-label="Refresh"
                  class="button button-purple mobile__wrapper tw-my-6"
                  width="100%"
                  @click="onClickToggleContinue"
                  :disabled="!isProjectTypeSelected"
                  >continue</v-btn
                >
              </div>
            </div>
          </div>
          <!-- Routine -->
          <div
            v-if="
              userProjectType == ProjectTypesEnum.ROUTINE &&
              currentSelectedTab.id === 3
            "
            class="routine__timeline"
          >
            <div class="">
              <div class="property_badge">
                <div class="notification">
                  {{ currentSelectedTab.id }}
                </div>
                <div class="property_badge_text">
                  {{ currentSelectedTab.name }}
                </div>
              </div>

              <div class="main_titlesection md:!tw-mt-[2px]">
                <h3 class="md:!tw-text-[18px]">
                  {{ currentSelectedTab.title }}
                </h3>
              </div>

              <div class="project-steps-content">
                <div
                  class="project-service-list third_step routine__timeline__step"
                >
                  <h4>How often would you like this project to repeat?</h4>
                  <div class="project-service-list-content">
                    <div class="project-service-list-card">
                      <v-card
                        class="project-service-item"
                        v-for="(item, index) in routineTypeList"
                        :key="index"
                        :class="{ selected: item.selected }"
                        @click="toggleSelectionType(item.name)"
                      >
                        <div class="project-service-item__text">
                          <h6>{{ item.name }}</h6>
                        </div>
                      </v-card>
                    </div>

                    <div class="calendar_container">
                      <div class="calendar_container_empty">
                        <p>
                          <b>You may cancel your current project if needed.</b>
                        </p>
                      </div>
                      <!-- <DatePicker
                        :masks="masks"
                        class="calendar__select"
                        v-model.range="dataRange"
                        mode="date"
                      /> -->
                    </div>
                  </div>
                  <div class="footer__btn tw-mt-6">
                    <v-btn
                      aria-label="Refresh"
                      class="button button-purple"
                      @click="onClickToggleContinue"
                      :disabled="!isProjectTypeSelected"
                      >continue</v-btn
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="editor__wrapper"
          v-if="isShowJobSpecifics && currentSelectedTab.id == 4"
        >
          <div class="property_badge">
            <div class="notification">
              {{ currentSelectedTab.id }}
            </div>
            <div class="property_badge_text">
              {{ currentSelectedTab.name }}
            </div>
          </div>

          <div class="main_titlesection md:!tw-mt-[2px]">
            <h3 class="md:!tw-text-[18px]">{{ currentSelectedTab.title }}</h3>
          </div>

          <div class="text__editor">
            <v-text-field
              class="projecttitle_select md:!tw-text-[16px] md:!tw-pt-0"
              placeholder="Project title"
              v-model="projectTitle"
              variant="underlined"
              density="compact"
            ></v-text-field>
            <div class="custom_editor">
              <quill-editor
                v-model:value="state.content"
                :options="state.editorOption"
                :disabled="state.disabled"
                @change="onEditorChange($event)"
                @focus="onEditorFocus($event)"
                @ready="onEditorReady"
                class="editor_ql"
              />

              <div class="gemini-model-wrapper"></div>
            </div>
          </div>
          <div class="selected-info">
            <v-btn
              aria-label="Refresh"
              class="button button-purple"
              @click="onClickToggleContinueJobDetails"
              :disabled="!isDisableJobDetailsContinue"
              >continue</v-btn
            >
          </div>
        </div>
        <div
          class="attachment__wrapper"
          v-if="isShowAttachments && currentSelectedTab.id == 5"
        >
          <div class="property_badge">
            <div class="notification">
              {{ currentSelectedTab.id }}
            </div>
            <div class="property_badge_text">
              {{ currentSelectedTab.name }}
            </div>
          </div>

          <div class="main_titlesection md:!tw-mt-[2px]">
            <h3 class="md:!tw-text-[18px]">{{ currentSelectedTab.title }}</h3>
          </div>

          <div class="attachment__wrapper__upload">
            <div class="left_upload_img">
              <div class="upload__area">
                <h4>
                  Upload images and files.
                  <span>(Optional)</span>
                </h4>
                <div class="upload__section">
                  <p class="tw-hidden">
                    Attachments such as images, design ideas and documents help
                    better understand the project and provide increasingly
                    accurate estimates.
                  </p>
                  <div class="uploade-wrapper custom-file-upload">
                    <v-file-input
                      class="upload_file"
                      label="Drag and drop documents or browser"
                      accept="image/png, image/jpeg, image/jpg"
                      variant="solo-filled"
                      v-model="selectedFile"
                      :class="{ 'added-img': imageUrl }"
                      @change="onFileChange"
                      @click:clear="clearImageUrl"
                      multiple
                    >
                      <v-icon
                        icon="mdi mdi-file-image-box"
                        class="label-img"
                      ></v-icon>
                    </v-file-input>
                    <v-img
                      v-if="imageUrl"
                      class="uploaded-img"
                      :width="200"
                      aspect-ratio="16/9"
                      cover
                      :src="imageUrl"
                    ></v-img>
                    <p class="error_msg" v-if="errorMessage">
                      {{ errorMessage }}
                    </p>
                  </div>
                </div>
                <div class="selected-info">
                  <!-- <v-btn
                    aria-label="Refresh"
                    class="button button-orange"
                    @click="addPropertyImage"
                    :disabled="!uploadedImages"
                    >{{
                      attachmentDataList.length ? "ADD ANOTHER" : "add"
                    }}</v-btn
                  > -->

                  <v-btn
                    aria-label="Refresh"
                    class="button button-purple"
                    @click="onClickPostProject"
                    :disabled="!isAttachmentDataValid"
                    :loading="loading"
                    >PREVIEW PROJECT</v-btn
                  >
                </div>
              </div>
            </div>

            <div class="image__upload__wrapper">
              <div v-if="attachmentDataList.length">
                <h5>Uploaded Files</h5>
                <div class="uploaded_details_wrapper">
                  <div
                    class="show_uploaded_details"
                    v-for="(attachment, index) in attachmentDataList"
                    :key="index"
                  >
                    <v-img
                      class="uploaded-img"
                      :width="156"
                      :height="88"
                      aspect-ratio="16/9"
                      cover
                      :src="attachment?.uploadedImages?.url"
                    ></v-img>
                    <div class="prop_img_details">
                      <h5>{{ attachment?.uploadedImages?.name }}</h5>
                      <v-btn
                        @click="removeImageFromAttachments(index)"
                        variant="text"
                      >
                        <img src="../../../assets/icons/delete.svg" alt="" />
                      </v-btn>
                    </div>

                    <div class="upload__list">
                      <v-select
                        v-model="attachment.propertyId"
                        label="Select property"
                        :items="userSelectedProperties"
                        item-title="address"
                        item-value="id"
                        multiple
                        variant="outlined"
                        class="selectprop_dropdown"
                        :menu-props="{ contentClass: 'upload_img__dropdown' }"
                      >
                      </v-select>
                      <!-- <v-text-field
                        density="compact"
                        placeholder="E.g. Public liability"
                        variant="outlined"
                        v-model="attachment.userCategoryName"
                        class="selectprop_dropdown"
                        :menu-props="{ contentClass: 'text__selector' }"
                      >
                        <v-icon
                          icon="mdi-menu-down"
                          :id="`menu-activator-${index}`"
                          class="cursor-pointer"
                        ></v-icon>
                      </v-text-field> -->
                      <v-combobox
                        class="selectprop_dropdown"
                        v-model="attachment.userCategoryName"
                        :items="userCategory"
                        placeholder=" E.g. Public liability"
                        :menu-props="{ contentClass: 'text__selector' }"
                      ></v-combobox>
                    </div>
                    <!-- <v-menu
                      transition="slide-y-reverse-transition"
                      class="slider-menu-active"
                      :activator="`#menu-activator-${index}`"
                      :active="true"
                    >
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in userCategory"
                          :key="index"
                        >
                          <v-list-item-title
                            class="cursor-pointer"
                            @click="attachment.userCategoryName = item.name"
                            >{{ item.name }}</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu> -->
                  </div>
                </div>
              </div>
              <div v-else class="blank_project">
                <p>Your added project attachments will show up here</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section_wrapper timeline__right">
        <div class="tab__area__right__block">
          <v-card class="tab__area__right__block__card third_step">
            <div class="content">
              <h4 class="title">Project Overview</h4>
            </div>
            <div class="tab__area__price">
              <ul>
                <h6 class="subtitle">Project Details</h6>
                <li>
                  <div class="tab__area__item">Job Type</div>
                  <div class="tab__area__item__price yellow-text">
                    <p class="yellow-text">{{ userProjectType }}</p>
                  </div>
                </li>
                <li
                  v-if="
                    currentSelectedTab.id == 4 || currentSelectedTab.id == 5
                  "
                >
                  <div class="tab__area__item">Timeline</div>
                  <div class="tab__area__item__price yellow-text">
                    <p class="yellow-text">
                      {{ ProjectDetails?.projectEndDate }}
                    </p>
                  </div>
                </li>
                <li v-if="currentSelectedTab.id == 5">
                  <div class="tab__area__item">Project Name</div>
                  <div class="tab__area__item__price yellow-text">
                    <p class="yellow-text">{{ projectTitle }}</p>
                  </div>
                </li>
                <h6 class="subtitle">Property Information</h6>
                <li>
                  <div class="tab__area__item">Properties</div>
                  <div class="tab__area__item__price yellow-text">
                    <p class="yellow-text">
                      {{ userSelectedProperties?.length }}
                    </p>
                  </div>
                </li>
                <li>
                  <div class="tab__area__item">Property Nicknames</div>
                  <div class="tab__area__sub__items">
                    <div
                      class="tab__area__item__price yellow-text"
                      v-for="(item, index) in userSelectedProperties"
                      :key="index"
                    >
                      <p class="yellow-text">{{ item.nickname }}</p>
                    </div>
                  </div>
                </li>
              </ul>
              <div v-if="workflowType === PropertyWorkflowTypeEnum.PROPERTY">
                <h5>Team Members</h5>
                <v-btn
                  class="button button-purple-border"
                  size="large"
                  block
                  @click="onInviteTeamMember"
                >
                  invite team member to project
                </v-btn>
              </div>
            </div>
            <v-btn
              v-if="workflowType === PropertyWorkflowTypeEnum.PROPERTY"
              class="button button-purple-border"
              size="large"
              block
              @click="saveForDraftProject"
              :loading="isShowLoadingOnScreen"
            >
              save project for later
            </v-btn>
            <!-- <label class="yellow-text"
            >Load Project Template <v-icon icon="mdi-chevron-right"></v-icon
          ></label> -->
          </v-card>
        </div>
      </div>
    </div>

    <InviteTeamMember
      @onCloseModal="onCloseModal"
      v-if="iShowInviteTeamMemberModal"
    />

    <v-stepper
      v-if="!isShowProjectReview"
      class="onboard-stepper"
      v-model="currentSelectedTab.id"
    >
      <v-stepper-header class="onboard-stepper__header">
        <v-stepper-item
          class="onboard-stepper__item"
          v-for="step in modelDetails"
          :key="step.id"
          :title="step.tab"
          :value="step.id"
        ></v-stepper-item>
      </v-stepper-header>
    </v-stepper>
  </div>
  <!-- <ProjectDetailsPage
    v-if="isShowProjectReview"
    :projectRefId="projectRefId"
    @onClickeditProject="onClickeditProject"
    type="projectReview"
  /> -->
</template>

<script lang="ts">
import {
  ref,
  onBeforeMount,
  computed,
  reactive,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
import { DatePicker } from "v-calendar";
import { quillEditor } from "vue3-quill";
import { PROJECT } from "@/store/modules/project";
import {
  ProjectJobStatusEnum,
  ProjectTypesEnum,
} from "@/core/enums/ProjectsEnum";
import { USER_STORE } from "@/store/modules/user";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { useRouter } from "vue-router";
import InviteTeamMember from "./InviteTeamMember.vue";
// import GeminiModelForSpecialism from "./common/GeminiModelForSpecialism.vue";
// import GeminiModelForMobileView from "./common/GeminiModelForMobileView.vue";
import { useDisplay } from "vuetify";
import { PropertyWorkflowTypeEnum } from "@/core/enums/PropertiesEnum";
import { JOB_DETAILS_ROUTE } from "@/modules/jobs/routes";
import { JOBS_STORE } from "@/store/modules/jobs";

export default {
  components: {
    DatePicker,
    quillEditor,
    // ProjectDetailsPage,
    // CommonDialog,
    InviteTeamMember,
    // GeminiModelForSpecialism,
    // GeminiModelForMobileView,
  },
  props: {
    workflowType: {
      type: String,
      default: PropertyWorkflowTypeEnum.PROPERTY,
    },
    selectedClient: {
      type: Object,
      default: null,
    },
    projectRefData: {
      type: Array,
      default: () => [],
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const userProjectType = ref("");
    const isShowJobSpecifics = ref(false);
    const isShowAttachments = ref(false);
    const userSelectedProperties = ref();
    const imageUrl = ref("");
    const errorMessage = ref("");
    const uploadedImages = ref(null) as any;
    const attachmentDataList = ref([]) as any;
    const projectTitle = ref("");
    const userSelectedPropertiesId = ref();
    const loading = ref(false);
    const isShowLoadingOnScreen = ref(false);
    const iShowInviteTeamMemberModal = ref(false);
    const projectRefId = ref("");
    const dataRange = ref();
    const router = useRouter();
    const geminiModalMenu = ref(false);
    const vuetify = useDisplay();
    const isDatePickerDisable = ref();
    const internalInstance = getCurrentInstance();

    const userCategory = ["Floorplan", "Garden", "Materials", "Maps"];

    const isShowProjectReview = ref(false);
    const showGeminiModel = ref(false);
    const showGeminiModelForMobile = ref(false);
    const isMobileView = computed(() => vuetify.smAndDown.value);
    const siteVisit = ref(false);

    const selectedFile = ref([]);
    onBeforeMount(() => {
      userProjectType.value =
        store.getters[`${PROJECT_STORE}/projectDetails`]?.projectType;
      if (
        userProjectType.value == ProjectTypesEnum.EMERGENCY ||
        userProjectType.value == ProjectTypesEnum.VIDEO_CONSULTATION
      ) {
        isShowJobSpecifics.value = true;
        currentSelectedTab.value = modelDetails.value[3];
      }

      userSelectedProperties.value =
        store.getters[`${PROJECT_STORE}/selectedProperties`];
      standardTypeList.value.forEach((item: any) => {
        if (item.name.toLowerCase() == props.projectRefData?.subType) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });

      routineTypeList.value.forEach((item: any) => {
        if (item.name.toLowerCase() == props.projectRefData?.subType) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
    });
    const onlyOne = ref(true);
    // onUpdated(() => {
    //   if (
    //     isShowJobSpecifics.value &&
    //     currentSelectedTab.value.id == 4 &&
    //     onlyOne.value
    //   ) {
    //     const toolbarElement = document.querySelector(
    //       ".ql-toolbar"
    //     ) as HTMLElement;
    //     const topPadding = toolbarElement.offsetHeight;
    //     if (isMobileView.value) {
    //       const cursorBox: any = document.getElementById(
    //         "quill-cursor-box-mobile"
    //       ) as HTMLElement;
    //       cursorBox.style.top = `${topPadding + 12}px`;
    //     } else {
    //       const cursorBox: any = document.getElementById("quill-cursor-box");
    //       cursorBox.style.top = `${topPadding + 12}px`;
    //     }
    //     onlyOne.value = false;
    //   }
    // });
    // onMounted(() => {});
    if (props.projectRefData?.projectAttachmentProperties) {
      attachmentDataList.value =
        props.projectRefData?.projectAttachmentProperties.map((item: any) => ({
          uploadedImages: {
            url: item[0]?.attachmentUrl,
            name: item[0]?.projectAttechment?.originalName,
          },
          propertyId: [item[0]?.property?.id],
          userCategoryName: item[0]?.projectAttachmentsCategory?.value,
        }));
    }

    const ProjectDetails = computed(
      () => store.getters[`${PROJECT_STORE}/projectDetails`]
    );
    projectTitle.value = ProjectDetails.value?.projectTitle;

    const modelDetails = ref([
      {
        id: 1,
        tab: "Property",
        name: "Property",
        title: "Please select the property / properties this project is for ",
      },
      {
        id: 2,
        tab: "Project Type",
        name: "Project Type",
        title: "Please select the type, best suited for your project.",
      },
      {
        id: 3,
        tab: "Timelines",
        name: "Timelines",
        title: "Let us know the timelines you’re looking for",
      },
      {
        id: 4,
        tab: "Job Specifics",
        name: "Job Specifics",
        title: "Add in specific details about the job",
      },
      {
        id: 5,
        tab: "Documents",
        name: "Attachments",
        title: "Add any relevant images and documents.",
      },
    ]);
    const currentSelectedTab = ref(modelDetails.value[2]);

    const standardTypeList = ref([
      {
        id: 1,
        name: "Flexible",
        iconName: "model_training_black",
        subTitle:
          "Let your local experts propose a start date, for your project.",
      },
      {
        id: 2,
        name: "Schedule",
        iconName: "clock_circle",
        subTitle: "Select the dates that best work for your project.",
      },
    ]) as any;

    const routineTypeList = ref([
      {
        id: 1,
        name: "Weekly",
      },
      {
        id: 2,
        name: "Fortnightly",
      },
      {
        id: 3,
        name: "Monthly",
      },
      {
        id: 4,
        name: "Yearly",
      },
    ]) as any;

    const isFlexibleTypeSelected = computed(() => {
      return standardTypeList.value.find(
        (item: any) => item.selected && item.name === "Flexible"
      );
    });
    const isProjectTypeSelected = computed(() => {
      const standardTypeSelected = standardTypeList.value.find(
        (item: any) => item.selected
      );
      const routineTypeSelected = routineTypeList.value.some(
        (item: any) => item.selected
      );

      if (standardTypeSelected) {
        if (standardTypeSelected.id === 1) {
          if (dataRange.value?.start) {
            dataRange.value = [];
          }
          isDatePickerDisable.value = [
            {
              repeat: {
                weekdays: [1, 2, 3, 4, 5, 6, 7],
              },
            },
          ];
          return true;
        } else if (standardTypeSelected.id === 2) {
          isDatePickerDisable.value = [];
          return dataRange.value;
        }
      }

      return routineTypeSelected;
    });

    const isAttachmentDataValid = computed(() => {
      if (!attachmentDataList.value || attachmentDataList.value.length === 0) {
        return false;
      }

      return attachmentDataList.value.every(
        (attachment: { propertyId: null; userCategoryName: null }) =>
          attachment.propertyId !== null && attachment.userCategoryName !== null
      );
    });

    const toggleSelectionType = (name: string) => {
      if (userProjectType.value === ProjectTypesEnum.STANDARD) {
        standardTypeList.value.forEach((item: any) => {
          if (item.name == name) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
      } else if (userProjectType.value === ProjectTypesEnum.ROUTINE) {
        routineTypeList.value.forEach((item: any) => {
          if (item.name === name) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
      }
    };

    const masks = {
      weekdays: "WWW",
    };

    const calenderAttribute = [{}];

    const onClickBackButton = () => {
      if (currentSelectedTab.value.id == 5) {
        currentSelectedTab.value = modelDetails.value[3];
        isShowJobSpecifics.value = true;
      } else if (currentSelectedTab.value.id == 4) {
        if (userProjectType.value == ProjectTypesEnum.EMERGENCY) {
          ctx.emit("onClickBackButtonType");
        }
        isShowJobSpecifics.value = false;
        isShowAttachments.value = false;
        currentSelectedTab.value = modelDetails.value[2];
      } else {
        ctx.emit("onClickBackButtonType");
      }
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);

      // Extracting date components
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    };

    const onClickToggleContinue = () => {
      let selectedItems = [];
      if (userProjectType.value === ProjectTypesEnum.STANDARD) {
        selectedItems = standardTypeList.value.filter(
          (item: { selected: any }) => item.selected
        );
      } else if (userProjectType.value === ProjectTypesEnum.ROUTINE) {
        selectedItems = routineTypeList.value.filter(
          (item: { selected: any }) => item.selected
        );
      }
      store.dispatch(`${PROJECT}/projectDetails`, {
        projectSubType: selectedItems[0]?.name.toLowerCase(),
        projectStartDate: dataRange.value?.start
          ? formatDate(dataRange.value?.start)
          : null,
        projectEndDate: dataRange.value?.end
          ? formatDate(dataRange.value?.end)
          : null,
      });
      currentSelectedTab.value = modelDetails.value[3];
      isShowJobSpecifics.value = true;
    };

    const onClickToggleContinueJobDetails = () => {
      store.dispatch(`${PROJECT}/projectDetails`, {
        projectTitle: projectTitle.value,
        projectDescription: state.content,
      });
      isShowJobSpecifics.value = false;
      currentSelectedTab.value = modelDetails.value[4];
      isShowAttachments.value = true;
    };

    const state = reactive({
      content: "",
      _content: "",
      editorOption: {
        placeholder: "Description here",
        modules: {},
      },
      disabled: false,
    });

    state.content = ProjectDetails.value?.projectDescription;

    const onEditorReady = (quill: any) => {
      // quillInstance.value = quill;
    };
    // const quillInstance = ref();
    // const quillCursorIndex = ref(0);
    const onEditorFocus = (quill: any) => {
      // try {
      //   showButtonForGeminiModel.value = true;
      //   quillInstance.value = quill;
      //   const range = quill.getSelection();
      //   console.log("range :>> ", range);
      //   quill.insertText(range?.index, "");
      // } catch (error) {
      //   console.log(error);
      // }
    };

    // const onEditorReady = (quill: any) => {
    //   console.log("editor ready!", quill);
    // };

    const showButtonForGeminiModel = ref(false);
    let hideBtn: any;
    const onEditorChange = ({
      quill,
      html,
      text,
    }: {
      quill: any;
      html: string;
      text: string;
    }) => {
      try {
        // quillInstance.value = quill;
        // showButtonForGeminiModel.value = false;

        // const range = quill.getSelection();
        // console.log(
        //   "leaf :>> ",
        //   range?.index,
        //   quill?.getLeaf(range?.index),
        //   quillCursorIndex.value
        // );
        // const [clientX, clientY]: any = getCaretCoordinates(
        //   quill,
        //   range?.index
        // );

        // const toolbarElement = document.querySelector(
        //   ".ql-toolbar"
        // ) as HTMLElement;
        // const topPadding = toolbarElement.offsetHeight;
        // if (isMobileView.value) {
        //   const cursorBox: any = document.getElementById(
        //     "quill-cursor-box-mobile"
        //   );
        //   cursorBox.style.top = `${clientY + topPadding}px`;
        //   cursorBox.style.left = `${clientX + 10}px`;
        // } else {
        //   const cursorBox: any = document.getElementById("quill-cursor-box");
        //   cursorBox.style.top = `${clientY + topPadding}px`;
        //   cursorBox.style.left = `${clientX + 10}px`;
        // }

        state._content = html;
        // quillCursorIndex.value = range?.index;
        // showButtonForGeminiModel.value = true;
      } catch (error) {
        console.log(error);
      }
    };

    // function getCaretCoordinates(quill: any, index: any) {
    //   try {
    //     const editorBounds = quill?.container.getBoundingClientRect();
    //     const range = document.createRange();
    //     const leaf = quill?.getLeaf(index)[0];
    //     const node = leaf?.domNode;
    //     const offset = index - leaf?.offset(quill.root);

    //     range.setStart(node, offset);
    //     range.setEnd(node, offset);

    //     const rect = range.getBoundingClientRect();
    //     return [rect.left - editorBounds?.left, rect.top - editorBounds?.top];
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    const saveForDraftProject = async () => {
      try {
        let attachments = [];
        let propertiesData = [];
        isShowLoadingOnScreen.value = true;
        store.commit(`${PROJECT}/setDraftProjectList`, [
          { type: userProjectType.value },
        ]);
        // await store.dispatch(`${PROJECT_STORE}/updateProject`, {
        //   data: { projectStatus: ProjectStatusType.DRAFT_PROJECT },
        //   projectId: ProjectData.value?.id,
        // });
        if (attachmentDataList.value.length) {
          const formData = new FormData();
          attachmentDataList.value.forEach((attachment: any) => {
            formData.append(`files`, attachment.uploadedImages.imageData);
          });
          const uploadMultipleImage = await store.dispatch(
            `${USER_STORE}/uploadMultipleImage`,
            formData
          );

          const attachmentCategoryData: any = [];

          for (const attachment of attachmentDataList.value) {
            const existCategory = attachmentCategoryData.find(
              (category: any) => category.label === attachment.userCategoryName
            );
            if (existCategory) {
              attachmentCategoryData.push(existCategory);
            } else {
              const response = await store.dispatch(
                `${PROJECT_STORE}/attachmentCategory`,
                {
                  label: attachment.userCategoryName,
                  value: attachment.userCategoryName,
                }
              );
              attachmentCategoryData.push(response);
            }
          }

          const selectedProperties =
            store.getters[`${PROJECT_STORE}/selectedProperties`];
          propertiesData = selectedProperties.map((property: { id: any }) => ({
            propertyId: property.id,
          }));
          const userPropertiesData = attachmentDataList.value.map(
            (item: any) => {
              return item.propertyId.map((property: any) => {
                return {
                  propertyId: property,
                  isDeleted: false,
                };
              });
            }
          );

          attachments = uploadMultipleImage.map((item: any, index: number) => ({
            publicUrl: item.publicUrl,
            attachment: item.attachment,
            originalName: item.originalName,
            propertyIds: userPropertiesData[index],
            attechmentCategoryId: attachmentCategoryData[index]?.id,
            isDeleted: false,
          }));
        }

        const createProject = await store.dispatch(
          `${PROJECT_STORE}/createProject`,
          {
            propertiesData: propertiesData,
            projectJobStatusId: ProjectJobStatusEnum.DRAFT_PROJECT,
            type: ProjectDetails.value?.projectType,
            subType: ProjectDetails.value?.projectSubType,
            description: state.content,
            name: projectTitle.value,
            startDate: ProjectDetails.value?.projectStartDate,
            endDate: ProjectDetails.value?.projectEndDate,
            inviteMembers: ProjectDetails.value?.inviteMembers,
            attachments: attachments,
            siteVisitStatus: siteVisit.value,
            isAddSpecialismFromGemini: true,
          }
        );
      } catch (error) {
        console.log("error", error);
      } finally {
        isShowLoadingOnScreen.value = false;
        ctx.emit("onClickSaveLater");
      }
    };

    const onFileChange = async (event: any) => {
      const fileInput = event.target;
      errorMessage.value = "";
      if (fileInput.files && fileInput.files.length > 0) {
        Array.from(fileInput.files).forEach((file: any) => {
          if (file.type.includes("image")) {
            // Check image size limit (200MB)
            if (file.size <= 200 * 1024 * 1024) {
              imageUrl.value = URL.createObjectURL(file);
              uploadedImages.value = {
                url: imageUrl.value,
                name: file.name,
                imageData: file,
              };
              addPropertyImage();
            } else {
              errorMessage.value = "Image size exceeds the limit (200MB).";
              // Handle error message or display notification
            }
          } else if (file.type.includes("video")) {
            // Check video size limit (20MB)
            if (file.size <= 10 * 1024 * 1024) {
              uploadedImages.value = {
                type: "video",
                name: file.name,
                imageData: file,
              };
              addPropertyImage();
              // Process video file
            } else {
              errorMessage.value = "Video size exceeds the limit (20MB).";
              // Handle error message or display notification
            }
          } else {
            // Allow other file types without size validation
            imageUrl.value = URL.createObjectURL(file);
            uploadedImages.value = {
              url: imageUrl.value,
              name: file.name,
              imageData: file,
            };
            addPropertyImage();
          }
        });
      } else {
        console.error("Error: No file selected.");
      }
    };
    const clearImageUrl = () => {
      imageUrl.value = "";
      errorMessage.value = "";
    };

    const addPropertyImage = () => {
      attachmentDataList.value.push({
        uploadedImages: { ...uploadedImages.value },
        propertyId:
          userSelectedProperties.value?.length > 1
            ? null
            : [userSelectedProperties.value[0]?.id],
        userCategoryName: null,
      });
      uploadedImages.value = null;
      selectedFile.value = [];
      imageUrl.value = "";
    };

    const removeImageFromAttachments = (index: number) => {
      attachmentDataList.value.splice(index, 1);
      clearImageUrl();
    };

    const isDisableJobDetailsContinue = computed(() => {
      return state.content && projectTitle.value;
    });

    const onClickPostProject = async () => {
      try {
        loading.value = true;
        if (props.isEditMode) {
          if (attachmentDataList.value.length) {
            const formData = new FormData();
            attachmentDataList.value.forEach((attachment: any) => {
              formData.append(`files`, attachment.uploadedImages.imageData);
            });
            const uploadMultipleImage = await store.dispatch(
              `${USER_STORE}/uploadMultipleImage`,
              formData
            );

            const attachmentCategoryData = await Promise.all(
              attachmentDataList.value.map(
                async (attachment: any) =>
                  await store.dispatch(`${PROJECT_STORE}/attachmentCategory`, {
                    label: attachment.userCategoryName,
                    value: attachment.userCategoryName,
                  })
              )
            );
            const propertiesData = props.projectRefData?.projectProperties.map(
              (property: any) => ({
                id: property.id,
                propertyId: property?.property?.id,
                isDeleted: false,
              })
            );
            const userPropertiesData = attachmentDataList.value.map(
              (item: any) => {
                return item.propertyId.map((property: any) => {
                  return {
                    propertyId: property,
                    isDeleted: false,
                  };
                });
              }
            );

            const attachments = uploadMultipleImage.map(
              (item: any, index: number) => ({
                publicUrl: item.publicUrl,
                attachment: item.attachment,
                originalName: item.originalName,
                propertyIds: userPropertiesData[index],
                attechmentCategoryId: attachmentCategoryData[index]?.id,
                isDeleted: false,
              })
            );
            const createProject = await store.dispatch(
              `${PROJECT_STORE}/updateProject`,
              {
                projectId: props.projectRefData?.id,
                data: {
                  propertiesData: propertiesData,
                  projectJobStatusId: ProjectJobStatusEnum.DRAFT_PROJECT,
                  type: ProjectDetails.value?.projectType,
                  subType: ProjectDetails.value?.projectSubType,
                  description: ProjectDetails.value?.projectDescription,
                  name: ProjectDetails.value?.projectTitle,
                  attachments: attachments,
                  siteVisitStatus: siteVisit.value,
                },
              }
            );
            if (createProject) {
              projectRefId.value = createProject?.refId;
              isShowProjectReview.value = true;
            }
          }
        } else {
          if (attachmentDataList.value.length) {
            const formData = new FormData();
            attachmentDataList.value.forEach((attachment: any) => {
              formData.append(`files`, attachment.uploadedImages.imageData);
            });
            const uploadMultipleImage = await store.dispatch(
              `${USER_STORE}/uploadMultipleImage`,
              formData
            );

            const attachmentCategoryData: any = [];

            for (const attachment of attachmentDataList.value) {
              const existCategory = attachmentCategoryData.find(
                (category: any) =>
                  category.label === attachment.userCategoryName
              );
              if (existCategory) {
                attachmentCategoryData.push(existCategory);
              } else {
                const response = await store.dispatch(
                  `${PROJECT_STORE}/attachmentCategory`,
                  {
                    label: attachment.userCategoryName,
                    value: attachment.userCategoryName,
                  }
                );
                attachmentCategoryData.push(response);
              }
            }

            const selectedProperties =
              store.getters[`${PROJECT_STORE}/selectedProperties`];
            const propertiesData = selectedProperties.map(
              (property: { id: any }) => ({
                propertyId: property.id,
              })
            );
            const userPropertiesData = attachmentDataList.value.map(
              (item: any) => {
                return item.propertyId.map((property: any) => {
                  return {
                    propertyId: property,
                    isDeleted: false,
                  };
                });
              }
            );

            const attachments = uploadMultipleImage.map(
              (item: any, index: number) => ({
                publicUrl: item.publicUrl,
                attachment: item.attachment,
                originalName: item.originalName,
                propertyIds: userPropertiesData[index],
                attechmentCategoryId: attachmentCategoryData[index]?.id,
                isDeleted: false,
              })
            );

            // Create project Property Side
            let payload = {
              propertiesData: propertiesData,
              projectJobStatusId: ProjectJobStatusEnum.DRAFT_PROJECT,
              // inviteTeamMember:[]
              type: ProjectDetails.value?.projectType,
              subType: ProjectDetails.value?.projectSubType,
              description: ProjectDetails.value?.projectDescription,
              name: ProjectDetails.value?.projectTitle,
              startDate: ProjectDetails.value?.projectStartDate,
              endDate: ProjectDetails.value?.projectEndDate,
              // inviteMembers: ProjectDetails.value?.inviteMembers,
              attachments: attachments,
              siteVisitStatus: siteVisit.value,
              isAddSpecialismFromGemini: true,
            } as any;

            if (props.workflowType === PropertyWorkflowTypeEnum.RAISE_CLIENT) {
              // Raise Project logic
              payload = {
                ...payload,
                createdUserId: props.selectedClient?.toUser?.id,
                createdWorkStationId: props.selectedClient?.userWorkstation?.id,
                projectJobStatusId: ProjectJobStatusEnum.AWAITING_QUOTES,
              };
              const createProject = await store.dispatch(
                `${PROJECT_STORE}/createRaiseProject`,
                payload
              );

              if (createProject) {
                projectRefId.value = createProject?.refId;
                store.commit(`${JOBS_STORE}/setJobDetails`, {});
                router.push({
                  name: JOB_DETAILS_ROUTE,
                  params: { refId: projectRefId.value },
                });
              }
            } else {
              const createProject = await store.dispatch(
                `${PROJECT_STORE}/createProject`,
                payload
              );
              if (createProject) {
                projectRefId.value = createProject?.refId;
                // isShowProjectReview.value = true;

                router.push({
                  name: "projectDetails",
                  params: { refId: projectRefId.value },
                });
              }
            }
          }
        }
      } catch (error: any) {
        console.log();
        displayToast(error?.response?.data?.message, "error");
      } finally {
        loading.value = false;
      }
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };
    const onClickeditProject = (value: any) => {
      ctx.emit("onClickeditProjectPropertyList", value);
    };

    const onInviteTeamMember = () => {
      iShowInviteTeamMemberModal.value = true;
    };

    const onCloseModal = () => {
      iShowInviteTeamMemberModal.value = false;
    };

    // const appendJobDescription = (description: string) => {
    // quillInstance.value.focus();
    // quillInstance.value?.insertText(
    //   quillCursorIndex.value,
    //   description.replaceAll("\n", " ")
    // );
    // onCloseHandler();
    // };
    const onCloseHandler = () => {
      // geminiModalMenu.value = false;
      showGeminiModel.value = false;
      showGeminiModelForMobile.value = false;
      // quillInstance.value.focus();
    };

    return {
      modelDetails,
      dataRange,
      currentSelectedTab,
      userProjectType,
      standardTypeList,
      isProjectTypeSelected,
      toggleSelectionType,
      calenderAttribute,
      masks,
      onClickBackButton,
      routineTypeList,
      onClickToggleContinue,
      isShowJobSpecifics,
      onClickToggleContinueJobDetails,
      isShowAttachments,
      state,
      onEditorReady,
      onEditorFocus,
      onEditorChange,
      saveForDraftProject,
      userSelectedProperties,
      imageUrl,
      onFileChange,
      clearImageUrl,
      addPropertyImage,
      attachmentDataList,
      removeImageFromAttachments,
      userCategory,
      uploadedImages,
      errorMessage,
      isShowProjectReview,
      projectTitle,
      onClickPostProject,
      ProjectTypesEnum,
      isDisableJobDetailsContinue,
      userSelectedPropertiesId,
      loading,
      projectRefId,
      isAttachmentDataValid,
      onClickeditProject,
      ProjectDetails,
      isShowLoadingOnScreen,
      onInviteTeamMember,
      iShowInviteTeamMemberModal,
      onCloseModal,
      selectedFile,
      showGeminiModel,
      // appendJobDescription,
      showGeminiModelForMobile,
      onCloseHandler,
      showButtonForGeminiModel,
      geminiModalMenu,
      PropertyWorkflowTypeEnum,
      isDatePickerDisable,
      isFlexibleTypeSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/project/styles/projectType.scss";
.temp-box {
  position: absolute;
  top: 76px;
  left: 22px;
  z-index: 1000;
  height: 32px;
  width: 32px;
  box-shadow: var(
    --elevation-200-canvas,
    0px 1px 2px rgba(0, 0, 0, 0.1),
    0px 5px 8px rgba(0, 0, 0, 0.3)
  );
  border-radius: 0px 16px 16px 16px;
  background: #0d99ff;
  background-image: url(../../../assets/icons/krystal.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.desktop__cursor,
.desktop__wrapper {
  display: block;
  @include respond(s720) {
    display: none;
  }
}
.mobile__cursor,
.mobile__wrapper {
  display: none;
  @include respond(s720) {
    display: block;
  }
}
.calendar_container {
  position: relative;

  .calendar_container_empty {
    @include fluidFont(16, 16, 1.3);
    font-weight: 500;
    letter-spacing: 0.15px;
    color: #4f55f0;
    text-align: center;
  }
}

.disable-calender {
  pointer-events: none;
}
.calendar_current_day {
  position: absolute;
  right: 30px;
  top: 7px;
  z-index: 1;
  p {
    @include fluidFont(16, 16, 1.2);
    color: rgba($blueDark, 1);
    font-weight: 700;
  }
}

// .secondary_upload_file {
//   height: 3.50038rem !important;
//   margin: 30px auto 80px !important;

//   .uploaded-img {
//     display: none !important;
//   }

//   :deep(.upload_file) {
//     .v-input__control {
//       position: relative !important;
//     }

//     .v-field__append-inner {
//       .mdi-image-plus {
//         width: 65px;
//         height: 65px;
//         background: url("../../../assets/icons/image_placeholder.svg") no-repeat
//           center / contain;
//         opacity: 1;

//         &::before {
//           display: none;
//         }
//       }
//     }

//     .v-field {
//       background-color: transparent !important;
//     }

//     .v-field-label {
//       padding-left: 30px !important;
//       color: $blueDark;
//       font-weight: 600;
//     }

//     .v-input__append {
//       left: 56px !important;
//     }

//     .v-field__clearable {
//       display: none;
//     }

//     .v-field__input {
//       display: block !important;
//       font-size: 0;
//       line-height: 0;
//       opacity: 0;
//       user-select: none;
//     }
//   }
// }

.upload__area {
  width: 100% !important;
  text-align: left;
}

.custom-file-upload {
  width: 100% !important;
  height: 144px !important;
  margin: 0 !important;
  background-color: #fff !important;
  border-radius: 6px !important;

  :deep(.upload_file) {
    background-color: transparent;
    border: 1px dashed #00000066;
    border-radius: 6px !important;

    .v-field {
      background-color: transparent !important;
      border-radius: 0 !important;
    }

    .v-field-label {
      margin: 0 !important;
      bottom: initial !important;
      font-weight: 400 !important;
      color: rgba($blueDark, 0.5) !important;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      z-index: -1;
      top: 50% !important;
      white-space: normal;
      text-align: center;
      transform: translate(-50%, -50%) !important;

      @media (max-width: 767px) {
        flex-direction: column;
        width: 70%;
        white-space: pre-wrap;
        text-align: center;
      }

      &::before {
        content: "";
        position: relative;
        display: inline-flex;
        left: 0;
        top: 0;
        width: 28px;
        height: 25px;
        background: url("@/assets/icons/file-upload.svg") no-repeat center /
          contain;
      }
    }

    .v-field__clearable {
      border-radius: 6px !important;
    }
  }

  .added-img {
    .v-field-label {
      display: none !important;
    }
  }

  .uploaded-img {
    width: 100% !important;
    border-radius: 6px !important;
  }

  .selectprop_dropdown {
    padding-right: 0;
  }
}
:deep(.disable-calender) button {
  pointer-events: none;
}
.disable-calender .calendar_current_day p,
:deep(.disable-calender)
  .calendar__select
  .vc-pane-container
  .vc-pane-layout
  .vc-pane
  .vc-header
  .vc-title-wrapper
  .vc-title
  span,
:deep(.disable-calender)
  .calendar__select
  .vc-pane-container
  .vc-pane-layout
  .vc-pane
  .vc-weeks
  .vc-week
  .vc-day
  .vc-day-content {
  color: rgba($blueDark, 0.5) !important;
}
</style>
